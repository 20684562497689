import React from "react";
import style from "./style/sponsors.module.css";
const Sponsors = () => {
  const sponsors = [
    {
      id: 1,
      title: "Vega Okulları",
    },
    {
      id: 2,
      title: "Bahçeşehir Neşe Erberk Anaokulu",
    },
    {
      id: 3,
      title: "Bahçeşehir Laden Anaokulu",
    },
    {
      id: 4,
      title: "Bahçeşehir Elit Anaokulu",
    },
    {
      id: 5,
      title: "Bahçeşehir Çizmeli Kedi Anaokulu",
    },
    {
      id: 6,
      title: "Bahçeşehir Amman Anaokulu",
    },
    {
      id: 7,
      title: "Bahçeşehir Mygym",
    },
    {
      id: 8,
      title: "Bahçeşehir IQ Kids Anaokulu",
    },
  ];
  return (
    <section className={style.sponsors}>
      <div className="container">
        <h2 className={style.contact__title}>Çalıştığımız Kurumlar</h2>
        <div className={style.sponsors__wrapper}>
          <ul className={style.sponsors__list}>
            {sponsors.map((sponsor) => (
              <li className={style.sponsors__item} key={sponsor.id}>
                <h3 className={style.sponsors__title}>{sponsor.title}</h3>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Sponsors;


