import React from "react";
import { Header,BookForm,AboutSwim,HomeGallery,Subscribe,BlogHome, Footer, Branches, Sponsors } from "components";
const Home = () => {
  return <>
    <Header />
    <BookForm />

    <AboutSwim />
    <HomeGallery />
    <Branches />
    <Subscribe />
    <BlogHome />    <Sponsors />
    <Footer />
  </>
};

export default Home;
