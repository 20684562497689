import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const { origin } = new URL(config.url);
    let useOrigin = `${origin}/`;
    const allowedOrigins = [process.env.REACT_APP_ENDPOINT];
    const parsedToken = JSON.parse(
      localStorage.getItem("id@inner:val:threath")
    );

    const token = parsedToken ? parsedToken.accessToken : " ";
    if (allowedOrigins.includes(useOrigin)) {
      config.headers.authorization = token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const Login = async (input) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}auth/login`,
    input
  );

  return response.data;
};

// RESERVATION APIS
export const makeReserve = async (input) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}reserve/make_reservation`,
    input
  );

  return response.data;
};

export const getReserves = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}reserve/all_reservations`
  );

  return response.data;
};

export const deleteReserve = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_ENDPOINT}reserve/delete_reservation/${id}`
  );

  return response.data;
};

// SUBSCRIBE APIS

export const subscribe = async (input) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}sub_user/subscribe`,
    input
  );

  return response.data;
};

export const getSubscribes = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}sub_user/all_subscribe`
  );

  return response.data;
};

// Message APIS

export const sendMessage = async (input) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}message/add_message`,
    input
  );

  return response.data;
};

export const getMessages = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}message/all_messages`
  );

  return response.data;
};

export const deleteMessage = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_ENDPOINT}message/delete_message/${id}`
  );

  return response.data;
};

// BLOG APIS
export const createBlog = async (input) => {
  const response = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}blog/blog_add_new_item`,
    input
  );

  return response.data;
};

export const getBlogs = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}blog/blog_get_all_items`
  );

  return response.data;
};

export const deleteBlog = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_ENDPOINT}blog/blog_delete_item_by_id/${id}`
  );
  return response.data;
};

export const getBlogById = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}blog/blog_get_item_by_id/${id}`
  );

  return response.data;
};
