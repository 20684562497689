import React from "react";
import style from "../style/admin.module.css";
import { getSubscribes } from "API/API";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import moment from "moment";
const SubscribeMail = () => {
  const [subscribeData, setSubscribeData] = React.useState([]);
  const getSubscribeData = async () => {
    try {
      const response = await getSubscribes();
      setSubscribeData(response.data.reverse());
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getSubscribeData();
  }, []);

  return (
    <section className={style.admin_subscribe}>
      <h2 className={style.admin_subscribe_title}> Abonelikler </h2>
      <div className="container">
        <TableContainer>
          <span className="">Toplam Abone Sayısı: {subscribeData.length}</span>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Abone Olma Tarihi</Th>
              </Tr>
            </Thead>
            <Tbody>
              {subscribeData.map((item) => (
                <Tr key={item._id}>
                  <Td>{item.email}</Td>
                  <Td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

export default React.memo(SubscribeMail);
