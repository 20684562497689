import React from "react";
import style from "./style/branches.module.css";
import swimImg from "./assets/swim.png";
import judoImg from "./assets/judo.png";
import cimnasticImg from "./assets/dancer.png";
import pilatesImg from "./assets/pilates.png";
const Branches = () => {

const branchesData = [
    {
      id: 1,
      title: "Yüzme",
      img: swimImg,
    },
    {
      id: 2,
      title: "Judo",
      img: judoImg,
    },
    {
      id: 3,
      title: "Cimnastik",
      img: cimnasticImg,
    },
    {
      id: 4,
      title: "Pilates",
      img: pilatesImg,
    },
]

  return (
    <section className={style.branches}>
      <div className="container">
        <h2 className={style.contact__title}>Branşlarımız</h2>
        <div className={style.branches__wrapper}>
          <div className="row g-3">
            {branchesData.map((branch) => (
              <div className="col-md-3 col-6" key={branch.id}>
                <div className={style.branches__item}>
                  <img
                    src={branch.img}
                    alt={branch.title}
                    className={style.branches__img}
                  />
                  <h3 className={style.branches__title}>{branch.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Branches;
