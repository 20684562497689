import React from "react";
import style from "./style/about.module.css";
const AboutItem = ({ img,name,title }) => {
  return (
    <div className="col-md-6 p-3 col-lg-4">
      <div className={style.item}>
        <div className={style.img_area}>
          <img src={img} className={style.person_img} alt="" />
        </div>
        <div className={style.content}>
          <h3 className={style.name}>{name}</h3>
          <p className={style.designation}>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutItem;
