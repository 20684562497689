import React from "react";
import style from "../style/admin.module.css";
import { getBlogs, deleteBlog } from "API/API";
import moment from "moment";
import { Button } from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { useToast } from "@chakra-ui/react";
const BlogList = () => {
  const [blogs, setBlogs] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const toast = useToast();
  const getBlogData = async () => {
    try {
      const response = await getBlogs();
      setBlogs(response.data.reverse());
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getBlogData();
  }, [refresh]);

  const deleteItem = async (id) => {
    try {
      await deleteBlog(id);
      setRefresh(!refresh);
      toast({
        title: "Blog başarıyla silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (err) {
      console.log(err);
      toast({
        status: "error",
        title: err.response.data.error,
        duration: 10000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <div className={style.admin_blog_list}>
      {blogs?.length > 0 ? blogs.map((item) => (
        <div key={item._id} className={style.admin_blog_item}>
          <div className="row g-3">
            <div className="col-md-12 col-lg-6">
              <div className={style.admin_blog_image}>
                <img src={item.imageUrl} alt="blog" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-12 h-100 col-lg-6">
              <div className="d-flex justify-content-between align-items-start flex-column">
                <ul className={style.admin_blog_info}>
                  <li className={style.blog_list_item}>
                    <span className={style.blog_list_item_title}>Başlık:</span>
                    <span className={style.blog_list_item_value}>
                      {item.title}
                    </span>
                  </li>
                  <li className={style.blog_list_item}>
                    <span className={style.blog_list_item_title}>İçerik:</span>
                    <span className={style.blog_list_item_value}>
                      {item.content}
                    </span>
                  </li>
                </ul>
                <div className={style.admin_blog_footer}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <BiTime className="fs-5 me-2" />
                      <span className={style.blog_time}>
                        {" "}
                        {moment(item.createdAt).format("DD.MM.YYYY")}
                      </span>
                    </div>
                    <Button
                      colorScheme="red"
                      onClick={() => deleteItem(item._id)}
                    >
                      <BsTrash />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )) : <h5 className="text-center">Henüz blog yazınız yok.</h5>}
    </div>
  );
};

export default BlogList;
