import React from "react";
import style from "./style/subscribe.module.css";
import { Input, Button, FormControl } from "@chakra-ui/react";
import { subscribe } from "API/API";
import { useFormik } from "formik";
import validationSchema from "./validation";
import { useToast } from "@chakra-ui/react";
const Subscribe = () => {
  const toast = useToast();
  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await subscribe(values);
        values.email = "";
        toast({
          title: "Teşekkürler",
          position: "top",
          description: `Başarıyla abone oldunuz.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (err) {
        console.log(err.response.data.error);
        toast({
          title: "Hata",
          position: "top",
          description: `${err.response.data.error}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <section className={style.subscribe}>
      <div className="container">
        <h2 className={style.subscribe__title}>
          {" "}
          İndirim ve Avantajları Kaçırmayın{" "}
        </h2>
        <div className={style.sub_wrapper}>
          <div className={style.subscribe__form}>
            <FormControl isRequired>
              <Input
                value={values.email}
                backgroundColor="#fff"
                isInvalid={errors.email && touched.email}
                onChange={handleChange}
                name="email"
                type="text"
                id="mail"
                placeholder="E-Posta Adresiniz"
              />
            </FormControl>
          </div>

          <div className={style.subscribe__button}>
            <Button onClick={handleSubmit} colorScheme="yellow">
              Abone Ol
            </Button>
          </div>
        </div>
      </div>

      <div className={style.bg_fade}></div>
    </section>
  );
};

export default Subscribe;
