import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import style from "./style/admin.module.css";
import { NavLink } from "react-router-dom";

const MobileDrawer = ({ isOpen, onClose }) => {
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menü</DrawerHeader>

          <DrawerBody>
            <ul className={style.draw_list}>
              <li className={style.draw_item}>
                <NavLink to="/sec_admin" className={style.draw_link}>
                  Randevular
                </NavLink>
              </li>
              <li className={style.draw_item}>
                <NavLink to="/sec_admin/mesajlar" className={style.draw_link}>
                  Mesajlar
                </NavLink>
              </li>
              <li className={style.draw_item}>
                <NavLink to="/sec_admin/aboneler" className={style.draw_link}>
                  Aboneler
                </NavLink>
              </li>
              <li className={style.draw_item}>
                <NavLink
                  to="/sec_admin/blog_yonetimi"
                  className={style.draw_link}
                >
                  Blog Yönetimi
                </NavLink>
              </li>
            </ul>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
