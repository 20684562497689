import React from "react";
import style from "./style/admin.module.css";
import icon from "./assets/manager.png";
import { FormControl, Input, Button, FormLabel } from "@chakra-ui/react";
import { AdminContext } from "Context/AdminContext";
import { Login } from "API/API";
import { Alert } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const AdminLogin = () => {
  const { setUser } = React.useContext(AdminContext);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const loginUser = async () => {
    setLoading(true);
    try {
      const response = await Login({
        email,
        password,
      });
      setUser(response);
      setLoading(false);
      setErrorMessage("");
      localStorage.setItem("id@inner:val:threath", JSON.stringify(response));
      navigate("/sec_admin");
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser();
  };
  return (
    <section className={style.admin_login}>
      <div className="container">
        <div className="row algin-items-center justify-content-center">
          <div className="col-12">
            <div className={style.admin_login_area}>
              <div className={style.admin_login_content}>
                <div className={style.admin_login_img}>
                  <img
                    src={icon}
                    alt="icon"
                    className={style.admin_login_img_icon}
                  />
                </div>
                <h3 className={style.admin_login_content_title}>
                  Yönetici Paneli
                </h3>
                {errorMessage && <Alert status="error">{errorMessage}</Alert>}
                <form onSubmit={handleSubmit} action="">
                  <FormControl isRequired marginY={3}>
                    <FormLabel htmlFor="email">E-Posta</FormLabel>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      borderColor="#ddd"
                      id="email"
                      type="text"
                    />
                  </FormControl>
                  <FormControl isRequired marginY={3}>
                    <FormLabel htmlFor="password">Şifre</FormLabel>
                    <Input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      borderColor="#ddd"
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    onSubmit={handleSubmit}
                    isLoading={loading}
                    colorScheme="teal"
                    width="100%"
                    marginTop={5}
                  >
                    {" "}
                    Giriş Yap
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
