import React from "react";
import { AdminHeader, Reservations } from "components";
const AdminHome = () => {
  return (
    <>
      <AdminHeader />
      <Reservations />
    </>
  );
};

export default AdminHome;
