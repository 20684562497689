import React from "react";
import { Link } from "react-router-dom";
import style from "./style/footer.module.css";
import { HiLocationMarker } from "react-icons/hi";
import { BiTime } from "react-icons/bi";
import { AiTwotonePhone } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import logo from "./assets/logo.png";
import { FaFacebook, FaInstagram } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className="container">
        <div className="col-12">
          <div className={style.footer_top}>
            <ul className={style.footer_top_list}>
              <li className={style.footer_top_item}>
                <Link to="/" className={style.footer_top_link}>
                  ANASAYFA
                </Link>
              </li>
              <li className={style.footer_top_item}>
                <Link to="/tesislerimiz" className={style.footer_top_link}>
                  TESİSLERİMİZ
                </Link>
              </li>
              <li className={style.footer_top_item}>
                <Link to="/hakkimizda" className={style.footer_top_link}>
                  HAKKIMIZDA
                </Link>
              </li>
              <li className={style.footer_top_item}>
                <Link to="/galeri" className={style.footer_top_link}>
                  GALERİ
                </Link>
              </li>
              <li className={style.footer_top_item}>
                <Link to="/blog" className={style.footer_top_link}>
                  BLOG
                </Link>
              </li>
              <li className={style.footer_top_item}>
                <Link to="/iletisim" className={style.footer_top_link}>
                  İLETİŞİM
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row g-3 my-3">
          <div className="col-lg-4 col-md-6">
            <ul className={style.left_list}>
              <li className={style.left_item}>
                <HiLocationMarker className={style.left_icon} />
                <span className={style.left_text}>
                  Bahçeşehir 1. Kısım Mahallesi, Ardıç Caddesi No:41-47A A -
                  No:41-47B, D:B Blok
                </span>
              </li>
              <li className={style.left_item}>
                <BiTime className={style.left_icon} />
                <span className={style.left_text}>
                  7 Gün 09:00 - 20:00
                </span>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 py-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={logo} className={style.footer_logo} alt="" />
              <div className={style.footer_social}>
                <a
                  href="https://www.instagram.com/dnaakademi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className={style.footer_social_icon} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
          <ul className={style.left_list}>
              <li className={`justify-content-center ${style.left_item}`}>
                <AiTwotonePhone className={style.left_icon} />
                <span className={style.left_text}>
                +90 532 497 88 61
                </span>
              </li>
              <li className={`justify-content-center ${style.left_item}`}>
                <FiMail className={style.left_icon} />
                <span className={style.left_text}>
                  dinamiknesiller@gmail.com
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
