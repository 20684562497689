import React from "react";
import style from "../style/admin.module.css";
import { Button } from "@chakra-ui/react";
import AddBlog from "./AddBlog";
import BlogList from "./BlogList";
const BlogManagement = () => {
  const [show, setShow] = React.useState("items");
  return (
    <section className={style.admin_blog}>
      <h2 className={style.admin_blog_title}>Blog Yönetimi</h2>
      <div className="d-flex align-items-center justify-content-center">
        <Button marginX={2} onClick={() => setShow("items")}>
          Bloglar
        </Button>
        <Button marginX={2} onClick={() => setShow("add")}>
          Blog Ekle
        </Button>
      </div>
      <div className="container">
        <div className="col-12">{show === "add" ? <AddBlog />: <BlogList />}</div>
      </div>
    </section>
  );
};

export default BlogManagement;
