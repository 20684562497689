import { getReserves, deleteReserve } from "API/API";
import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import style from "../style/admin.module.css";
const Reservations = () => {
  const [reserveData, setReserveData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const getReserveData = async () => {
    try {
      const response = await getReserves();
      setReserveData(response.reservations.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getReserveData();
  }, [refresh]);

  const deleteItem = async (id) => {
    try {
      await deleteReserve(id);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={style.book_area}>
      <h2 className={style.book_area_title}>Randevular</h2>
      <div className="container">
        <TableContainer>
          <span className="">Toplam Randevu Sayısı: {reserveData.length}</span>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>İsim Soyisim</Th>
                <Th>Telefon</Th>
                <Th>Tarih</Th>
                <Th>Saat</Th>
                <Th>Oluşturma Tarihi</Th>
                <Th>İşlem</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reserveData.map((item) => (
                <Tr key={item._id}>
                  <Td>{item.name}</Td>
                  <Td>{item.phone}</Td>
                  <Td>{item.reservationDate}</Td>
                  <Td>{item.reservationTime}</Td>
                  <Td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</Td>
                  <Td>
                    {" "}
                    <Button
                      variantcolor="red"
                      onClick={() => deleteItem(item._id)}
                    >
                      Sil
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

export default Reservations;
