import React from "react";
import style from "./style/homeblog.module.css";
import { getBlogs } from "API/API";
import BlogItem from "./BlogItem";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
const BlogHome = () => {
  const [blogsData, setBlogsData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAllBlogs = async () => {
    setLoading(true);
    try {
      const response = await getBlogs();
      setBlogsData(response.data.reverse());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <section className={style.blog_home}>
      <div className="container">
        <h2 className={style.main_title}>Blog Yazılarımız</h2>
        <div className="row g-3">
          {loading ? (
            <></>
          ) : (
            blogsData.map((item, index) => {
              if (index < 3) {
                return <BlogItem key={index} item={item} />;
              }
            })
          )}
        </div>
        <div className="d-flex mt-5 justify-content-center align-items-center">
          <Link to="/blog">
            <Button colorScheme="yellow" size="lg">
              Daha Fazla Görüntüle
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogHome;
