import { Footer, Header,AboutUs } from 'components'
import React from 'react'

const About = () => {
  return (
    <>
    <Header />
    <AboutUs />
    <Footer />
    </>
  )
}

export default About