import React from "react";
import style from "../style/admin.module.css";
import {
  FormControl,
  Input,
  FormLabel,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { createBlog } from "API/API";
const AddBlog = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        title: "",
        content: "",
        imageUrl: "",
      },
      onSubmit: async (values) => {
        setLoading(true);
        const formData = new FormData();

        formData.append("title", values.title);
        formData.append("content", values.content);
        formData.append("image", imageFile);
        try {
          await createBlog(formData);
          toast({
            title: "Blog başarıyla oluşturuldu.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          values.title = "";
          values.content = "";
          values.imageUrl = "";
          setLoading(false);
        } catch (err) {
          console.log(err);
          toast({
            status: "error",
            title: err.response.data.error,
            duration: 10000,
            isClosable: true,
            position: "top",
          });
          setLoading(false);
        }
      },
    });

  return (
    <div className={style.add_blog}>
      <form onSubmitCapture={handleSubmit}>
        <div className="col-12">
          <FormControl marginY={5} isRequired>
            <FormLabel htmlFor="title">Başlık</FormLabel>
            <Input
              id="title"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              isInvalid={touched.title && errors.title}
              placeholder="Başlık"
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl marginY={5} isRequired>
            <FormLabel htmlFor="content">İçerik</FormLabel>
            <Textarea
              id="content"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.content}
              isInvalid={touched.content && errors.content}
              rows={6}
              name="content"
              placeholder="İçerik"
            />
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl marginY={5} isRequired>
            <FormLabel htmlFor="imageUrl">Resim</FormLabel>
            <input
              type="file"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </FormControl>
        </div>
        <div className="col-12">
          <Button
            onSubmit={handleSubmit}
            type="submit"
            colorScheme="teal"
            width="100%"
            isLoading={loading}
            size="lg"
          >
            Blog Ekle
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBlog;
