import React from "react";
import style from "./style/gallery.module.css";
import imgPath from "components/home_gallery/assets/hrzntl-1.jpg";
import GalleryItem from "./GalleryItem";
import img1 from "./assets/item-1.jpg";
import img2 from "./assets/item-2.jpg";
import img3 from "./assets/item-3.jpg";
import img4 from "./assets/item-4.jpg";
import img5 from "./assets/item-5.jpg";
import img6 from "./assets/item-6.jpg";
import img7 from "./assets/item-7.jpg";
import img8 from "./assets/item-8.jpg";
import img9 from "./assets/item-9.jpg";
import img10 from "./assets/item-10.jpg";
import img11 from "./assets/item-11.jpg";
import img12 from "./assets/item-12.jpg";
import img13 from "./assets/item-13.jpg";
import img14 from "./assets/item-14.jpg";
import img15 from "./assets/item-15.jpg";
import img16 from "./assets/item-16.jpg";
import img17 from "./assets/item-17.jpg";
import img18 from "./assets/item-18.jpg";
import img19 from "./assets/item-19.jpg";
import img20 from "./assets/item-20.jpg";
const GalleryAll = () => {

  return (
    <section className={style.gallery}>
      <div className="container">
        <h2 className={style.gallery__title}>Galeri</h2>
        <div className={style.gallery__content}>
          <div className="row g-3">
            <GalleryItem imageUrl={img1} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg6.jpeg"} />
            <GalleryItem imageUrl={img2} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg7.jpeg"} />
            <GalleryItem imageUrl={img3} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg3.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg8.jpeg"} />
            <GalleryItem imageUrl={img4} />
            <GalleryItem imageUrl={img5} />
            <GalleryItem imageUrl={img6} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg10.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg11.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg12.jpeg"} />
            <GalleryItem imageUrl={img7} />
            <GalleryItem imageUrl={img8} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg21.jpeg"} />
            <GalleryItem imageUrl={img9} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg16.jpeg"} />
            <GalleryItem imageUrl={img10} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg19.jpeg"} />
            <GalleryItem imageUrl={img15} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg13.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg14.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg15.jpeg"} />
            <GalleryItem imageUrl={img11} />
            <GalleryItem imageUrl={img12} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg4.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg5.jpeg"} />
            <GalleryItem imageUrl={img18} />
            <GalleryItem imageUrl={img19} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg17.jpeg"} />
            <GalleryItem imageUrl={img20} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg1.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg2.jpeg"} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg9.jpeg"} />
            <GalleryItem imageUrl={img13} />
            <GalleryItem imageUrl={img14} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg18.jpeg"} />
            <GalleryItem imageUrl={img16} />
            <GalleryItem imageUrl={img17} />
            <GalleryItem imageUrl={"/assets/images/gallery/swimg20.jpeg"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryAll;
