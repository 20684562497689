import React from "react";
import style from "./style/homegallery.module.css";
import hrzntl from "components/gallery/assets/item-3.jpg";
import hrzntl2 from "components/gallery/assets/item-4.jpg";
import hrzntl3 from "components/gallery/assets/item-7.jpg";
import vrtcl from "components/gallery/assets/item-5.jpg";
const HomeGallery = () => {
  return (
    <section className={style.home_gallery}>
      <div className="container">
        <h2 className={style.home_gallery_title}>Galeri</h2>
        <div className="row g-3">
          <div className="col-md-6 col-lg-6">
            <div className={style.home_gallery_wrapper_left}>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className={style.home_gallery_item}>
                    <img
                      src="/assets/images/gallery/swimg2.jpeg"
                      alt="gallery"
                      className={`${style.vertical_img} ${style.gallery_img}`}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row g-3">
                    <div className="col-12">
                      <div className={style.home_gallery_item}>
                        <img
                          src={hrzntl}
                          alt="gallery"
                          className={`${style.hrzntl_little} ${style.gallery_img}`}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={style.home_gallery_item}>
                        <img
                          src={hrzntl3}
                          alt="gallery"
                          className={`${style.hrzntl_little} ${style.gallery_img}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="row g-3">
              <div className="col-12 h-100">
                <div className={style.home_gallery_item}>
                  <img
                    src="/assets/images/gallery/swimg12.jpeg"
                    alt="gallery"
                    className={`${style.horizontal_big} ${style.gallery_img}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeGallery;
