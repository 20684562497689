import React from 'react'
import { AdminHeader } from 'components'
import SubscribeMail from 'components/admin/subscribe/SubscribeMail'
const AdminSubscribe = () => {
  return (
    <>
        <AdminHeader />
        <SubscribeMail />
    </>
  )
}

export default AdminSubscribe