import Whatsapp from "components/whatsapp_btn/Whatsapp";
import { AdminProvider } from "Context/AdminContext";
import ScrollToTop from "hooks/ScrollToTop";
import MainRoute from "Route/MainRoute";
function App() {
  return (
    <>
      <ScrollToTop />
      <AdminProvider>
        <MainRoute />
        <Whatsapp />
      </AdminProvider>
    </>
  );
}

export default App;
