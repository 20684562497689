import React from "react";
import style from "./style/bookform.module.css";
import icon from "./assets/girl.png";
import {
  FormControl,
  Select,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { makeReserve } from "API/API";
import { useToast, Alert } from "@chakra-ui/react";
import { Validation } from "./validation";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
const BookForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [dateValue, setDateValue] = React.useState(new Date());
  const toast = useToast();
  const { handleChange, handleSubmit, handleBlur, touched, errors, values } =
    useFormik({
      initialValues: {
        name: "",
        phone: "",
        reservationDate: moment(dateValue).format("YYYY-MM-DD"),
        reservationTime: "",
      },
      validationSchema: Validation,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const res = await makeReserve(values);
          setLoading(false);
          toast({
            title: "Teşekkürler",
            position: "top",
            description: `Ücretsiz demo randevunuz ${moment(dateValue).format(
              "YYYY-MM-DD"
            )} tarihinde, ${
              res.reservation.reservationTime
            } saati için başarıyla oluşturuldu.`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          values.name = "";
          values.phone = "";
          values.reservationTime = "";
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      },
    });

  const days = ["Pt", "Sa", "Ça", "Pe", "Cu", "Ct", "Pz"];
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "dd/mm/yyyy",
    },
  };
  return (
    <section className={style.bookform}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className={style.form_slogan}>
              <h2 className={style.form_slogan_title}>
                Ücretsiz demo ders için formu doldurarak online randevu
                alabilirsiniz.
              </h2>
              <div className={style.form_slogan_icon_area}>
                <img src={icon} alt="icon" className={style.form_slogan_icon} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className={style.form_area}>
              <h3 className={style.form_area_title}> Randevu Formu </h3>
              <form onSubmitCapture={handleSubmit} action="">
                <FormControl isRequired marginY={3}>
                  <FormLabel htmlFor="name">İsim Soyisim</FormLabel>
                  <Input
                    isInvalid={touched.name && errors.name}
                    borderColor="#ddd"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                  />
                </FormControl>
                <FormControl isRequired marginY={3}>
                  <FormLabel htmlFor="phone">Telefon</FormLabel>
                  <Input
                    name="phone"
                    isInvalid={touched.phone && errors.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    borderColor="#ddd"
                    type="tel"
                    maxLength={10}
                    id="phone"
                    placeholder="555 555 55 55"
                    value={values.phone}
                  />
                </FormControl>
                <FormControl isRequired marginY={3}>
                  <FormLabel htmlFor="date">Randevu Tarihi</FormLabel>
                  <DatePicker
                    className={style.datepicker}
                    locale={locale}
                    selected={dateValue}
                    onChange={(e) => setDateValue(e)}
                  />
                </FormControl>
                <FormControl isRequired marginY={3}>
                  <FormLabel htmlFor="hour">Randevu Saati</FormLabel>
                  <Select
                    borderColor="#ddd"
                    placeholder="00:00"
                    id="hour"
                    name="reservationTime"
                    size="md"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.reservationTime && errors.reservationTime
                    }
                  >
                    <option value="09:30">09:30</option>
                    <option value="10:00">10:00</option>
                    <option value="10:30">10:30</option>
                    <option value="11:00">11:00</option>
                    <option value="11:30">11:30</option>
                    <option value="12:00">12:00</option>
                    <option value="12:30">12:30</option>
                    <option value="13:00">13:00</option>
                    <option value="13:30">13:30</option>
                    <option value="14:00">14:00</option>
                    <option value="14:30">14:30</option>
                    <option value="15:00">15:00</option>
                    <option value="15:30">15:30</option>
                    <option value="16:00">16:00</option>
                    <option value="16:30">16:30</option>
                    <option value="17:00">17:00</option>
                    <option value="17:30">17:30</option>
                    <option value="18:00">18:00</option>
                    <option value="18:30">18:30</option>
                    <option value="19:00">19:00</option>
                  </Select>
                </FormControl>
                <Button
                  width={["100%", "100%", "100%", "100%", "100%"]}
                  loadingText="Submitting"
                  colorScheme="teal"
                  marginTop={5}
                  type="submit"
                  isLoading={loading}
                >
                  Randevu Al
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookForm;
