import React from "react";
import { Link, NavLink } from "react-router-dom";
import style from "./style/admin.module.css";
import logo from "./assets/manager.png";
import { Button } from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDisclosure } from "@chakra-ui/react";
import MobileDrawer from "./MobileDrawer";
const AdminHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <section className={style.admin_header}>
      <div className="container">
        <nav className={style.admin_header_nav}>
          <Link to="/sec_admin" className={style.admin_brand}>
            <img src={logo} alt="logo" className="img-fluid" />
          </Link>
          <ul className={style.admin_header_nav_list}>
            <li className={style.admin_header_nav_list_item}>
              <NavLink
                to="/sec_admin"
                className={style.admin_header_nav_link}
              >
                Randevular
              </NavLink>
            </li>
            <li className={style.admin_header_nav_list_item}>
              <NavLink
                to="/sec_admin/mesajlar"
                className={style.admin_header_nav_link}
              >
                Mesajlar
              </NavLink>
            </li>
            <li className={style.admin_header_nav_list_item}>
              <NavLink
                to="/sec_admin/aboneler"
                className={style.admin_header_nav_link}
              >
                Aboneler
              </NavLink>
            </li>
            <li className={style.admin_header_nav_list_item}>
              <NavLink
                to="/sec_admin/blog_yonetimi"
                className={style.admin_header_nav_link}
              >
                Blog Yönetimi
              </NavLink>
            </li>
          </ul>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              onClick={() => {
                localStorage.removeItem("id@inner:val:threath");
                window.location.reload();
              }}
              colorScheme="red"
              size="sm"
            >
              Çıkış Yap
            </Button>
            <div className={style.admin_mobile_icon}>
              <Button
                onClick={onOpen}
                colorScheme="transparent"
                size="lg"
                paddingRight={0}
                fontSize={35}
                className="text-dark"
              >
                {" "}
                <GiHamburgerMenu />{" "}
              </Button>
            </div>
          </div>
          <MobileDrawer isOpen={isOpen} onClose={onClose} />
        </nav>
      </div>
    </section>
  );
};

export default AdminHeader;
