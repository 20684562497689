import React from "react";
import style from "./style/facilities.module.css";
const AllFacilities = () => {
  const facilities = [
    {
      id: 1,
      title: "Başakşehir Gençlik ve Spor İlçe Müdürlüğü Tesisleri",
      location: (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.537284428384!2d28.7781135!3d41.122795599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb0699ee47cb%3A0xf003ab2949728ca5!2zQmHFn2FrxZ9laGlyIEdlbsOnbGlrIHZlIFNwb3IgxLBsw6dlIE3DvGTDvHJsw7zEn8O8IFRlc2lzbGVyaQ!5e0!3m2!1sen!2str!4v1679491229947!5m2!1sen!2str"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ),
      imageUrl: "/assets/images/facilities/basaksehir_havuz.jpg",
      description:
        "25m’lik Yarı Olimpik Yüzme Havuzunda Uzman Antrenörlerimiz Eşliğinde Başakşehir’deki 2. Şubesimizde derslerimiz başladı. Sizde Çocuklarınızı Sağlıklı Nesiller, Dinamik Bendenler ve Lisanslı, Elit, Profesyonel Sporcu olmasını istiyorsanız ÜCRETSİZ deneme derslerimize katılmak için bizimle iletişime geçebilirsiniz.",
    },
  ];

  return (
    <section className={style.facilities}>
      <div className="container">
        <div className="row g-3">
          {facilities.map((facility) => {
            return (
              <>
                <div key={facility.id} className="col-md-6">
                  <img src={facility.imageUrl} alt="" className="img-fluid" />
                </div>
                <div className="col-md-6">
                  <h2 className={style.facility_title}>{facility.title}</h2>
                  <p className={style.facility_text}>{facility.description}</p>
                </div>
                <div className="col-12">{facility.location}</div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AllFacilities;
