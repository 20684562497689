import React from "react";
import style from "./style/gallery.module.css";
const GalleryItem = ({ imageUrl }) => {
  return (
    <div className="col-lg-4 col-md-4">
      <div className={style.gallery__item}>
        <img src={imageUrl} className={style.gallery_img} alt="gallery_img" />
      </div>
    </div>
  );
};

export default GalleryItem;
