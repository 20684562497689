import * as Yup from "yup";

export const Validation = Yup.object({
  name: Yup.string()
    .min(2, "En az 2 karakter girmelisiniz")
    .required("Bu alan zorunlu"),
  phone: Yup.string()
    .min(10, "En az 10 karakter girmelisiniz")
    .required("Bu alan zorunlu"),
  reservationDate: Yup.string().required("Bu alan zorunlu"),
  reservationTime: Yup.string().required("Bu alan zorunlu"),
});
