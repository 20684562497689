import React from "react";
import { AdminHeader, BlogManagement } from "components";
const AdminBlog = () => {
  return (
    <>
      <AdminHeader />
      <BlogManagement />
    </>
  );
};

export default AdminBlog;
