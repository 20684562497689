import React from "react";
import style from "./style/blog.module.css";
import { getBlogs } from "API/API";
import { Box, Button, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { BsFillPersonFill, BsFillFolderFill } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";
const Blog = () => {
  const [blogsData, setBlogsData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAllBlogs = async () => {
    setLoading(true);
    try {
      const response = await getBlogs();
      setBlogsData(response.data.reverse());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <section className={style.blog}>
      <h2 className={style.blog_title}>Blog Yazılarımız</h2>
      <div className="container">
        {loading ? (
          <Box padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        ) : (
          <>
            {blogsData.map((item, index) => {
              return (
                <div key={index} className="col-12">
                  <div className={style.blog_item}>
                    <div className={style.blog_item_img_area}>
                      <img
                        src={item.imageUrl}
                        className={style.blog_img}
                        alt="blog"
                      />
                    </div>
                    <div className={style.blog_item_info}>
                      <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <div className="d-flex me-3 justify-content-start align-items-center">
                          <BsFillPersonFill
                            className={style.blog_icon}
                            size={20}
                          />{" "}
                          <span className={style.blog_item_info_author}>
                            PAYLAŞAN: ÖZGÜR
                          </span>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                          <BsFillFolderFill
                            className={style.blog_icon}
                            size={20}
                          />{" "}
                          <span className={style.blog_item_info_author}>
                            DNA YÜZME OKULLARI
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={style.blog_item_content}>
                      <h3 className={style.blog_item_title}>{item.title}</h3>
                      <div className={style.blog_item_desc_area}>
                        <p className={style.blog_item_text}>{item.content}</p>
                        <div className={style.blog_item_fade}></div>
                      </div>
                    </div>
                    <div className={style.blog_item_footer}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Link to={`/blog/${item._id}`}>
                          <Button
                            variantColor="green"
                            colorScheme="green"
                            size="md"
                          >
                            {" "}
                            Daha Fazla Oku{" "}
                          </Button>
                        </Link>
                        <div className="d-flex justify-content-end align-items-center">
                          <MdDateRange size={20} />{" "}
                          <span>
                            {moment(item.createdAt).format("DD.MM.YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </section>
  );
};

export default Blog;
