import { FacilitiesAll, Footer, Header } from "components";
import React from "react";

const Facilities = () => {
  return (
    <>
      <Header
        title="Tesislerimiz"
        subtitle="Dinamik Nesiller Akademisi DNA Spor Kulübü yine ve yeniden gururla sunar…"
        content="25m’lik Yarı Olimpik Yüzme Havuzunda Uzman Antrenörlerimiz Eşliğinde Başakşehir’deki 2. Şubesimizde derslerimiz başladı. Sizde Çocuklarınızı Sağlıklı Nesiller, Dinamik Bendenler ve Lisanslı, Elit, Profesyonel Sporcu olmasını istiyorsanız ÜCRETSİZ deneme derslerimize katılmak için bizimle iletişime geçebilirsiniz."
      />
      <FacilitiesAll />
      <Footer />
    </>
  );
};

export default Facilities;
