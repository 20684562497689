import React from "react";
import style from "./style/aboutswim.module.css";
import icon from "./assets/icon-1.png";
import icon2 from "./assets/icon-2.png";
import icon3 from "./assets/icon-3.png";
import icon4 from "./assets/icon-4.png";
const AboutSwim = () => {
  return (
    <section className={style.about_swim}>
      <div className="container">
        <div className="row g-3">
          <div className="col-lg-3 col-md-6 col-12">
            <div className={style.about_swim_item}>
              <div className={style.item_icon_wrapper}>
                <img src={icon4} alt="icon" className="img-fluid" />
              </div>
              <p className={style.item_text}>Tesislerimiz</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className={style.about_swim_item}>
              <div className={style.item_icon_wrapper}>
                <img src={icon3} alt="icon" className="img-fluid" />
              </div>
              <p className={style.item_text}>
                Performans ve Kursiyer Gruplarımız
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className={style.about_swim_item}>
              <div className={style.item_icon_wrapper}>
                <img src={icon2} alt="icon" className="img-fluid" />
              </div>
              <p className={style.item_text}>Ders Programlarımız</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className={style.about_swim_item}>
              <div className={style.item_icon_wrapper}>
                <img src={icon} alt="icon" className="img-fluid" />
              </div>
              <p className={style.item_text}>Çalıştığımız Kurumlar</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSwim;
