import React from "react";

export const AdminContext = React.createContext();

export const AdminProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const values = {
    user,
    setUser,
    loading,
    setLoading,
  };

  return (
    <AdminContext.Provider value={values}>{children}</AdminContext.Provider>
  );
};
