import React from "react";
import { Link } from "react-router-dom";
import style from "./style/header.module.css";
import logo from "./assets/logo.png";
import iconOne from "./assets/swimmer.png";
import iconTwo from "./assets/swimmer_girl.png";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import Navbar from "./Navbar";
import { Button } from "@chakra-ui/react";
import PageYOffset from "hooks/PageYOffset";
import DrawerMenu from "./Drawer";
import { useDisclosure } from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import ScreenWidth from "hooks/ScreenWidth";
const Header = ({ title = "", subtitle = "", content = "" }) => {
  const [scrolled, setScrolled] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { scrollTop } = PageYOffset();
  const { width } = ScreenWidth();
  const [isMobile, setIsMobile] = React.useState(false);
  const [mobileScrolled, setMobileScrolled] = React.useState(false);

  React.useEffect(() => {
    if (scrollTop > 170 && !scrolled && !isMobile) {
      setScrolled(true);
    } else if (scrollTop < 170 && scrolled && !isMobile) {
      setScrolled(false);
    } else if (scrollTop > 10 && isMobile && !mobileScrolled) {
      setMobileScrolled(true);
    } else if (mobileScrolled && scrollTop < 10) {
      setMobileScrolled(false);
    }
  }, [scrollTop, isMobile]);

  React.useEffect(() => {
    if (width > 550 && isMobile) {
      setIsMobile(false);
    } else if (width < 550 && !isMobile) {
      setIsMobile(true);
    }
  }, [width]);

  return (
    <header className={style.header}>
      <div className="container">
        <div
          className={`${style.header_top} ${
            !isMobile && scrolled && style.scrolled_header
          } ${mobileScrolled && style.scrolled_mobile}`}
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div
              className={`d-flex flex-column justify-content-center align-items-start ${style.header_top_left_area} `}
            >
              <p className={style.header_top_text}>
                <span className={style.header_top_text_span}>Bizi Arayın:</span>{" "}
                +90 532 497 88 61
              </p>
              <p className={style.header_top_text}>
                <span className={style.header_top_text_span}>
                  Mail Bırakın:
                </span>{" "}
                dinamiknesiller@gmail.com
              </p>
            </div>
            <Link to="/" className={style.header_brand}>
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
            <div
              className={`d-flex justify-content-end align-items-center ${style.icon_wrapper}`}
            >
              <a
                href="https://www.instagram.com/dnaakademi/"
                className={`${style.header_icon} ${isMobile && "d-none"}`}
              >
                <FaInstagram />
              </a>
              <Button
                onClick={onOpen}
                colorScheme="transparent"
                size="lg"
                fontSize={35}
                className={`ms-4 ${style.hamburger_btn} ${
                  mobileScrolled && style.scrolled_hamburger
                }`}
              >
                {" "}
                <GiHamburgerMenu />{" "}
              </Button>
            </div>
          </div>
        </div>
        {!isMobile && <Navbar />}

        <DrawerMenu isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        <div className={style.banner}>
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className={style.carousel_item}>
                  <h2 className={style.carousel_item_title}>
                    {title
                      ? title
                      : "DNA Dinamik Nesiller Akademisi Spor Kulübü"}
                  </h2>

                  {subtitle ?
                  
                  <p className={style.carousel_item_text}>
                    {subtitle}
                  </p>
                   : ""}

                  {content ? (
                  <p className={style.carousel_item_text}>  {content}   </p>
                  ) : (
                    
                    <p className={style.carousel_item_text}>
                       Bünyemizde bulunan her yaş kategorisindeki lisanslı ve
                      müsabık sporcularımız ile yeni başlayacak olan
                      öğrencilerimize deneyimli ve alanında uzman antrenörler
                      eşliğinde yarışma standartlarına uygun yarı olimpik yüzme
                      havuzunda lisanslı profesyonel sporcu olma imkanı
                      sunmaktayız. Çocuklarınızın elit seviyede sporcu olması
                      için bizimle iletişime geçebilirsiniz.
                    </p>
                  )}

                  <img
                    src={iconTwo}
                    alt="logo"
                    className={style.carousel_icon}
                  />
                  <Link to="/iletisim" className="text-light">
                    <Button size="lg" textColor="#333" colorScheme="yellow">
                      İletişim
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.header_fade}></div>
    </header>
  );
};

export default Header;
