import React from "react";
import style from "./style/whatsapp.module.css";
import Lottie from "lottie-react";
const Whatsapp = () => {
  return (
    <a
      className={style.whatsapp}
      href="https://wa.me/905324978861"
      target="_blank"
      rel="noreferrer"
    >
      <Lottie animationData={require("./assets/whatsapp.json")} autoplay loop />
    </a>
  );
};

export default Whatsapp;
