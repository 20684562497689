import React from "react";
import { Header, Footer,GalleryAll } from "components";
const Gallery = () => {
  return (
    <>
      <Header />
        <GalleryAll />
      <Footer />

    </>
  );
};

export default Gallery;
