import React from 'react'
import { AdminHeader,Messages } from 'components'
const AdminMessages = () => {
  return (
    <>
        <AdminHeader />
        <Messages />
    </>
  )
}

export default AdminMessages