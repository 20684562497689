import React from "react";
import style from "./style/header.module.css";
import { NavLink } from "react-router-dom";
import PageYOffset from "hooks/PageYOffset";
const Navbar = () => {
  const [scrolled, setScrolled] = React.useState(false);
  const { scrollTop } = PageYOffset();

  React.useEffect(() => {
    if (scrollTop > 170 && !scrolled) {
      setScrolled(true);
    } else if (scrollTop < 170 && scrolled) {
      setScrolled(false);
    }
  }, [scrollTop]);
  return (
    <nav className={`${style.navbar} ${scrolled && style.sticky_nav}`}>
      <div className={scrolled ? style.scrolled_inner : style.navbar_inner}>
        <ul className={style.navbar_list}>
          <li className={style.navbar_item}>
            <NavLink
              to="/"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              Anasayfa
            </NavLink>
          </li>
          <li className={style.navbar_item}>
            <NavLink
              to="/tesislerimiz"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              Tesislerimiz
            </NavLink>
          </li>
          <li className={style.navbar_item}>
            <NavLink
              to="/hakkimizda"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              Hakkımızda
            </NavLink>
          </li>
          <li className={style.navbar_item}>
            <NavLink
              to="/galeri"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              GALERİ
            </NavLink>
          </li>
          <li className={style.navbar_item}>
            <NavLink
              to="/blog"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              Blog
            </NavLink>
          </li>
          <li className={style.navbar_item}>
            <NavLink
              to="/iletisim"
              className={`${style.navbar_link} ${scrolled && "text-dark"}`}
            >
              İLETİŞİM
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
