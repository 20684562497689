import React from 'react'
import { Header, Footer, Blog } from 'components'
const BlogPage = () => {
  return (
    <>
        <Header />
        <Blog />
        <Footer />
    </>
  )
}

export default BlogPage