import React from "react";
import { Link } from "react-router-dom";
import style from "./style/contact.module.css";
import logo from "./assets/logo.png";
import { HiLocationMarker } from "react-icons/hi";
import { AiTwotonePhone } from "react-icons/ai";
import {
  FormControl,
  Input,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import validationSchema from "./validation";
import { sendMessage } from "API/API";
import { useToast } from "@chakra-ui/react";
const ContactUs = () => {
  const toast = useToast();
  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        name: "",
        phone: "",
        message: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        try {
          await sendMessage({
            name: values.name,
            phone: values.phone,
            message: values.message,
          });
          values.name = "";
          values.phone = "";
          values.message = "";
          toast({
            title: "Mesajınız başarıyla gönderildi.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        } catch (err) {
          console.log(err.response.data.error);
          toast({
            status: "error",
            title: err.response.data.error,
            duration: 10000,
            isClosable: true,
            position: "top",
          });
        }
      },
    });

  return (
    <>
      <section className={style.contact}>
        <div className="container">
          <h2 className={style.contact__title}>Bizimle İletişime Geçin</h2>
          <div className={style.contact__content}>
            <div className="row g-3">
              <div className="col-lg-4 col-md-6">
                <ul className={style.contact__list}>
                  <li className={style.contact__item}>
                    <Link to="/" className={style.item__link}>
                      <img src={logo} alt="logo" className="img-fluid m-auto" />
                    </Link>
                  </li>
                  <li className={style.contact__item}>
                    <div className="d-flex justify-content-start align-items-start">
                      <HiLocationMarker className={style.item__icon} />
                      <p className={style.item__text}>
                        Bahçeşehir 1. Kısım Mahallesi, Ardıç Caddesi No:41-47A A
                        - No:41-47B, D:B Blok
                      </p>
                    </div>
                  </li>
                  <li className={style.contact__item}>
                    <div className="d-flex justify-content-start align-items-start">
                      <AiTwotonePhone className={style.item__icon} />
                      <p className={style.item__text}>+90 532 497 88 61</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8 col-md-6">
                <form onSubmitCapture={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <FormControl marginY={2}>
                        <FormLabel htmlFor="name">İsim Soyisim</FormLabel>
                        <Input
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.name && touched.name}
                          borderColor="#ddd"
                          value={values.name}
                          id="name"
                          type="text"
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl marginY={2}>
                        <FormLabel htmlFor="phone">Telefon Numarası</FormLabel>
                        <Input
                          borderColor="#ddd"
                          name="phone"
                          maxLength={10}
                          onBlur={handleBlur}
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={errors.phone && touched.phone}
                          id="phone"
                          type="text"
                          placeholder="+90 555 555 55 55"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12">
                      <FormControl marginY={2}>
                        <FormLabel htmlFor="message">Mesajınız</FormLabel>
                        <Textarea
                          borderColor="#ddd"
                          size="lg"
                          name="message"
                          onBlur={handleBlur}
                          value={values.message}
                          isInvalid={errors.message && touched.message}
                          onChange={handleChange}
                          rows="10"
                          cols="50"
                        />
                      </FormControl>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        type="submit"
                        onSubmit={handleSubmit}
                        colorScheme="teal"
                        size="lg"
                      >
                        {" "}
                        Gönder
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={style.map_area}>
        <iframe
          height="300"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5845.396757695644!2d28.690945378496977!3d41.06156103667635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa766beb23ad1%3A0x5e39c1052c3b1fc5!2sDNA%20Dinamik%20Nesiller%20Akademisi%20(Vega%20Okullar%C4%B1%20Bah%C3%A7e%C5%9Fehir%20Kamp%C3%BCs%C3%BC)!5e0!3m2!1str!2str!4v1655485520488!5m2!1str!2str"
          width="100%"
          loading="lazy"
          title="map"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default ContactUs;
