import React from "react";
import style from "./style/blog.module.css";
import { getBlogById } from "API/API";
import { useParams } from "react-router-dom";
import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { BsFillPersonFill, BsFillFolderFill } from "react-icons/bs";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
const BlogPost = () => {
  const [blogData, setBlogData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const getBlog = async () => {
    setLoading(true);
    try {
      const response = await getBlogById(id);
      setBlogData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getBlog();
  }, []);
  return (
    <section className={style.blog}>
      <h2 className={style.blog_title}>Blog Yazılarımız</h2>
      <div className="container">
        {loading ? (
          <Box padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        ) : (
          <>
            <div className="col-12">
              <div className={style.blog_item}>
                <div className={style.blog_item_img_area}>
                  <img
                    src={blogData.imageUrl}
                    className={style.blog_img}
                    alt="blog"
                  />
                </div>
                <div className={style.blog_item_info}>
                  <div className="d-flex justify-content-start align-items-center flex-wrap">
                    <div className="d-flex me-3 justify-content-start align-items-center">
                      <BsFillPersonFill className={style.blog_icon} size={20} />{" "}
                      <span className={style.blog_item_info_author}>
                        PAYLAŞAN: ÖZGÜR
                      </span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <BsFillFolderFill className={style.blog_icon} size={20} />{" "}
                      <span className={style.blog_item_info_author}>
                        DNA YÜZME OKULLARI
                      </span>
                    </div>
                  </div>
                </div>
                <div className={style.blog_item_content}>
                  <h3 className={style.blog_item_title}>{blogData.title}</h3>
                  <div className={style.blog_item_desc_area}>
                    <p className={style.blog_item_text}>
                      {blogData.content}
                    </p>
                  </div>
                </div>
                <div className={style.blog_item_footer}>
                  <div className="d-flex justify-content-end align-items-center">
                    <MdDateRange size={20} />{" "}
                    <span>
                      {moment(blogData.createdAt).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BlogPost;
