import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import style from "./style/header.module.css";
import { Link } from "react-router-dom";
import {FaInstagram, FaFacebookF} from "react-icons/fa";
const DrawerMenu = ({ isOpen, onClose }) => {
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menü</DrawerHeader>
          <DrawerBody>
            <ul className={style.draw_list}>
              <li className={style.draw_item}>
                <Link to="/" className={style.draw_link}>
                  Anasayfa
                </Link>
              </li>
              <li className={style.draw_item}>
                <Link to="/tesislerimiz" className={style.draw_link}>
                  Tesislerimiz
                </Link>
              </li>
              <li className={style.draw_item}>
                <Link to="/hakkimizda" className={style.draw_link}>
                  Hakkımmızda
                </Link>
              </li>
              <li className={style.draw_item}>
                <Link to="/galeri" className={style.draw_link}>
                  Galeri
                </Link>
              </li>
              <li className={style.draw_item}>
                <Link to="/blog" className={style.draw_link}>
                  Blog
                </Link>
              </li>
              <li className={style.draw_item}>
                <Link to="/iletisim" className={style.draw_link}>
                  İletişim
                </Link>
              </li>
            </ul>

            <div className="d-flex justify-content-center align-items-center my-3">
              <a  className={style.header_icon} href="https://www.instagram.com/">
                <FaInstagram className={style.draw_icon} />
              </a>
              <a className={style.header_icon} href="https://www.facebook.com/">
                <FaFacebookF className={style.draw_icon} />
              </a>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
