import React from "react";
import style from "./style/about.module.css";
import imgPath from "components/gallery/assets/item-14.jpg";
import imgOne from "./assets/item-1.JPG";
import imgTwo from "./assets/item-2.JPG";
import imgThree from "./assets/item-3.JPG";
import imgFour from "./assets/item-4.JPG";
import AboutItem from "./AboutItem";
const AboutUs = () => {

  return (
    <section className={style.about}>
      <div className="container">
        <h2 className={style.title}>Hakkımızda</h2>
        <div className={style.content}>
          <div className="row g-3">
            <div className="col-lg-6 col-md-12">
              <h3 className={style.subtitle}>Ne Yapıyoruz ?</h3>
              <p className={style.description}>
                2017 yılında milli sporculardan oluşan ve çeşitli okullardaki
                beden eğitimi öğretmenleri tarafından kurulan kulübümüz, sporun,
                çocukların zihin ve bedensel gelişimi üzerindeki boyutunu ön
                planda tutarak, çeşitli spor dallarını oyunlarla harmanlayarak,
                onlara hem sporu sevdirmek hemde bir yaşam biçimi getirmeyi
                hedeflemektedir. Günümüz Türkiyesinde çocuklarımızın omuzlarına
                yeterince sınav yükü yüklenmişken aslında sınavlarda başarının
                sırrı sağlam bir vücuttan geçmekte olduğu gerçeği göz ardı
                edilmektedir. Bunun için çocuklarımızın öncelikle yaşam dinamiği
                içerisinde sportif faaliyetler ile birlikte beden ve zihin
                yapısı güçlendirilmeli ve bu destekten sınavlarda da
                faydalanılmalıdır.
              </p>
              <p className={style.description}>
                Spor bir zeka işidir. Her spor kendi içinde beynin farklı
                yönlerini hızlı bir şekilde kullanabilmeyi gerektirir. İşte bu
                nedenle küçük yaşlardan itibaren sistemli bir şekilde çeşitli
                spor branşlarının gerektirdiği teknik becerileri oyunlarla
                kazandırılmaya çalışılıp ilerleyen yıllarda çocuklarımızın
                yetenekli olduğu spor branşına yönlendirmeyi planlamaktayız.
              </p>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className={style.img_area}>
                <img src={imgPath} className={style.img} alt="" />
              </div>
            </div>
          </div>
          <div className={style.teacher_area}>
            <h2 className={style.title}>Eğitmenlerimiz</h2>
            <div className="row g-3">
              <AboutItem img={imgOne} name="Aykut KAYALILAR" title="Baş Antrenör" />
              <AboutItem img={imgFour} name="Berna ÇETİNKAYA" title="Antrenör" />
              <AboutItem img={imgThree} name="Sinan YAVUZ" title="Antrenör" />
              <AboutItem img={imgTwo} name="Nurkan KARADAYI" title="Antrenör" />
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
