import { Route, Routes } from "react-router-dom";
import React from "react";
import {
  Home,
  About,
  AdminHome,
  Gallery,
  Contact,
  AdminSubscribe,
  AdminMessages,
  AdminBlog,
  BlogPage,
  BlogSpesific,
  Facilities
} from "pages";
import { AdminLogin } from "components";
import { Protected, ProtectedLogin } from "./Protected";
const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/hakkimizda" element={<About />} />
      <Route path="/galeri" element={<Gallery />} />
      <Route path="/iletisim" element={<Contact />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route
        path="/sec_admin"
        element={
          <Protected>
            <AdminHome />
          </Protected>
        }
      />

      <Route
        path="/sec_admin/login"
        element={
          <ProtectedLogin>
            <AdminLogin />
          </ProtectedLogin>
        }
      />
      <Route
        path="/sec_admin/aboneler"
        element={
          <Protected>
            <AdminSubscribe />
          </Protected>
        }
      />
      <Route
        path="/sec_admin/mesajlar"
        element={
          <Protected>
            <AdminMessages />
          </Protected>
        }
      />
      <Route
        path="/sec_admin/blog_yonetimi"
        element={
          <Protected>
            <AdminBlog />
          </Protected>
        }
      />
      <Route path="/blog/:id" element={<BlogSpesific />} />
      <Route path="/tesislerimiz" element={<Facilities />} />
    </Routes>
  );
};

export default MainRoute;
