import React from "react";
import { Header, Footer, ContactUs } from "components";
const Contact = () => {
  return (
    <>
      <Header />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Contact;
