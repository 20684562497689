import { Navigate } from "react-router-dom";
import React from "react";
export const Protected = ({ children }) => {
  const isLoggedIn = localStorage.getItem("id@inner:val:threath") || false;
  return isLoggedIn ? children : <Navigate to="/sec_admin/login" />;
};
export const ProtectedLogin = ({ children }) => {
  const isLoggedIn = localStorage.getItem("id@inner:val:threath") || false;
  return isLoggedIn ? <Navigate to="/sec_admin" /> : children;
};
