import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import { getMessages, deleteMessage } from "API/API";
import style from "../style/admin.module.css";
import moment from "moment";
const Messages = () => {
  const [messages, setMessages] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const getMessageData = async () => {
    try {
      const response = await getMessages();
      setMessages(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getMessageData();
  }, [refresh]);

  const deleteItem = async (id) => {
    try {
      await deleteMessage(id);
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className={style.admin_messages}>
      <h2 className={style.admin_messages_title}>Mesajlar</h2>
      <div className="container">
        <TableContainer>
          <span className="">Toplam Mesaj Sayısı: {messages?.length}</span>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>İsim Soyisim</Th>
                <Th>Telefon</Th>
                <Th>Mesaj</Th>
                <Th>Oluşturma Tarihi</Th>
                <Th>İşlem</Th>
              </Tr>
            </Thead>
            <Tbody>
              {messages.map((item) => (
                <Tr key={item._id}>
                  <Td>{item.name}</Td>
                  <Td>{item.phone}</Td>
                  <Td>{item.message}</Td>
                  <Td>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</Td>
                  <Td>
                    {" "}
                    <Button
                      variantcolor="red"
                      onClick={() => deleteItem(item._id)}
                    >
                      Sil
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

export default Messages;
