import { Footer, Header, BlogPost } from "components";
import React from "react";

const BlogSpesific = () => {
  return (
    <>
      <Header />
      <BlogPost />
      <Footer />
    </>
  );
};

export default BlogSpesific;
