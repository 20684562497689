import style from "./style/homeblog.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { BiTime } from "react-icons/bi";
import moment from "moment";
import { Button } from "@chakra-ui/react";
const BlogItem = ({ item }) => {

  return (
    <div className="col-lg-4 col-md-6">
      <div className={style.card}>
        <div className={style.card_img_wrapper}>
          <img src={item.imageUrl} className={style.card_img} alt="" />
        </div>
        <div className={style.card_body}>
          <Link to={`/blog/${item.id}`} className={style.card_title}>
            {item.title}
          </Link>
         <div className={style.card_desc_area}>
         <p className={style.card_description}>{item.content}</p>
         <div className={style.card_desc_fade}></div>
         </div>
          <div className="d-flex justify-content-between align-items-center">
           <Link to={`/blog/${item._id}`}>
           <Button colorScheme="green" size="sm"> Daha Fazla Oku</Button>
           </Link>
          <div className="d-flex align-items-center justify-content-end">
            <BiTime className="me-2" />
            <span className={style.card_time}>
              {moment(item.createdAt).format("DD.MM.YYYY")}
            </span>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
